body.lightMode {
  background-color: #ffffff;
  color: #1c1c1c;
}
.lightMode .funtionDtlSec {
  background-color: #fff;
}

.lightMode
  .FuntionSlider-container
  .slick-slider
  .slick-list
  .slick-track
  .slick-slide
  > div {
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  border-radius: 26px;
}
.lightMode .MuiButtonBase-root.MuiButton-root.vabtn::before {
  border-left: 2px solid #1c1c1c;
  border-right: 2px solid #1c1c1c;
}

.lightMode .MuiButtonBase-root.MuiButton-root.vabtn::after {
  border-top: 2px solid #1c1c1c;
  border-bottom: 2px solid #1c1c1c;
}

.lightMode .MuiButtonBase-root.MuiButton-root.vabtn {
  color: #1c1c1c;
}
.lightMode .indFunSlidCtrl .MuiButtonBase-root.MuiIconButton-root {
  background-color: #1c1c1c;
  color: #ffffff;
}

.lightMode .appDownloadSec {
  background-color: #f0f0f0;
}

.lightMode .adnTitle {
  color: #1c1c1c;
}
.lightMode .adnText {
  color: #333;
}
.lightMode a .appStore {
  border: 2px solid #545454;
}
.lightMode .adnLinkGrp a {
  text-decoration: none;
  color: #1c1c1c;
}
.lightMode .latestNewsSec {
  background-color: #ffffff;
}
.lightMode .mapBg {
  background-image: url(./features/landingPage/images/newsBGLightMode.png);
}
.lightMode .sectionTitle {
  color: #1c1c1c;
}
.lightMode .slick-prev:before,
.lightMode .slick-next:before {
  color: #707070;
}
.lightMode .lnSliderContainer .slick-dots li button {
  background: #707070 !important;
}
.lightMode .lnSliderContainer .sliderElement {
  background-color: #f0f0f0 !important;
  border: 1px solid #545454 !important;
}
.lightMode .slidTitle {
  color: #1c1c1c;
}
.lightMode .slidTxt,
.lightMode .slidPublishDT {
  color: #333;
}
.lightMode .logRegFullContainer {
  background-color: #ffffff;
}
.lightMode .LogRegisterUser {
  color: #aaaaaa;
}
.lightMode .actionText {
  color: #46c6ff;
}
.lightMode .closelrCon .MuiIconButton-root svg {
  color: #545454;
}
.lightMode .formTextFieldArea input {
  color: #333 !important;
  background-color: #ffffff !important;
}
.lightMode .formControl .MuiFormLabel-root.MuiInputLabel-root {
  font-size: 14px;
  line-height: 34px;
  font-family: Inter;
  color: rgb(51 51 51 / 60%) !important;
  transform: translate(14px, 4px);
}

.lightMode input:-webkit-autofill,
.lightMode input:-webkit-autofill:hover,
.lightMode input:-webkit-autofill:focus,
.lightMode input:-webkit-autofill:active {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0 50px #ffffff inset !important;
  color: #333 !important;
  -webkit-text-fill-color: #333 !important;
}
/* //Landing page light mode end */

/* Header Page lightmode start */
.lightMode .header {
  background-color: #ffffff !important;
  background-image: linear-gradient(#ffffff, #ffffff) !important;
  height: 50px;
  box-shadow: none !important;
}
.lightMode .orgNameTypography {
  color: #1c1c1c;
}
.lightMode .formElement.docOnOffOption {
  color: #1c1c1c;
  border-right: 1px solid #cccccc;
}
.lightMode .supportOption .MuiButtonBase-root.MuiButton-root.support-button {
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; */
  box-shadow: none;
  color: #1c1c1c;
  margin-right: 5px;
}
.lightMode .langOption .formControl .MuiSelect-select.MuiSelect-outlined {
  color: #1c1c1c;
  opacity: 1;
}
.lightMode .MuiSvgIcon-root.MuiSelect-icon {
  color: rgb(51 51 51 / 60%);
}
.lightMode .userInfo {
  color: #1c1c1c;
}
.lightMode .UserOrg {
  opacity: 0.8;
}
.lightMode .tabs-container {
  background-color: #cccccc;
}
.lightMode .homeContainerArea {
  background-color: #ffffff;
}
.lightMode .tab.active {
  background-color: #ffffff !important;
  color: #1c1c1c !important;
}
.lightMode .tab.active .tabsText {
  color: #1c1c1c !important;
}
.lightMode .tabsText {
  color: #545454 !important;
}
.lightMode .tab {
  color: #545454 !important;
  background-color: #e0e0e0 !important;
}

.lightMode .tab::after {
  border-top: 18px solid #cccccc !important;
}
.lightMode .hcFixed .tabs .TabWrapper:nth-child(2) .tab::after,
.lightMode .TabWrapper:first-child .tab::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 0;
  height: 0;
  border-left: 14px solid transparent !important;
  border-right: 0px solid transparent !important;
  border-top: 18px solid transparent !important;
  clear: both;
}
.lightMode .closeTab {
  position: relative !important;
  background-color: #cccccc;
  border-radius: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
}
.lightMode .hmBtnActive .hmTbText {
  color: #1c1c1c !important;
}
.lightMode .hmTabActvIndcator {
  background: #545454 !important;
}
.lightMode .taskCardContainer {
  border-bottom: 1px solid #cccccc;
}
.lightMode .taskCardContainer:hover {
  background-color: #f9f9f9 !important;
}
.lightMode .tskInOutIoc svg path {
  fill: #1c1c1c !important;
}
.lightMode .tskTitle {
  color: #1c1c1c !important;
}
.lightMode .tskTimeHeading {
  color: #333 !important;
}
.lightMode .tskDTEnd,
.lightMode .tskDTStart {
  color: #545454 !important;
}
.lightMode .tskTypLabel {
  background-color: #dddddd !important;
  color: #1c1c1c !important;
}
.lightMode .asgnUsrImg img {
  border: 1.3px solid #545454;
}
.lightMode .tskAsginHead {
  color: #333333 !important;
  opacity: 0.7;
}
.lightMode .tskAsginUsrName {
  color: #333333 !important;
}
.lightMode .metDesTitle {
  color: #333333 !important;
  opacity: 0.7;
}
.lightMode .metDesDtil {
  color: #545454 !important;
}
.lightMode .tskAsgnUsr {
  align-items: center;
}

.lightMode .dashboardTaskContainer,
.lightMode .dashboardMeetingsContainer,
.lightMode .dashboardChatContainer,
.lightMode .activeContainer {
  background-color: #f9f9f9;
}
.lightMode .taskViewPannel {
  border-left: 0.1px solid #cccccc;
  background-color: #f9f9f9;
}
.lightMode .taskViewContainer {
  background-color: #f9f9f9;
}
.lightMode .taskHeaderPanel {
  background-color: #f9f9f9;
  border-bottom: 1px solid #cccccc;
}
.lightMode .taskTitleNM {
  color: #1c1c1c !important;
}
.lightMode .taskContractNm,
.lightMode .taskElementGrp .tskElemntInfo {
  color: #1c1c1c !important;
  font-weight: 400;
}
.lightMode .tskElemHeding {
  color: #1c1c1c !important;
  opacity: 0.7;
}
.lightMode .vitNm {
  color: #797979 !important;
}
.lightMode .vitIcon svg {
  fill: #797979 !important;
}
.lightMode .vitalsInd {
  background-color: #dadada;
}
.lightMode .vitalsInd:hover {
  background-color: #cccccc;
}
.lightMode .complaintList ul,
.lightMode .compntName {
  color: #1c1c1c !important;
}
.lightMode .compStime,
.lightMode .compSeverity {
  color: #707070 !important;
  background-color: #dadada;
}
.lightMode .compStime .data,
.lightMode .compSeverity .data {
  color: #545454 !important;
}
.lightMode .tskUCNM {
  color: #1c1c1c !important;
  font-weight: 500;
}
.lightMode .tskUCDT {
  color: #1c1c1c;
  font-weight: 400 !important;
  opacity: 0.7;
}
.lightMode .taskUPComnt {
  color: #1c1c1c !important;
}
.lightMode .tskInputGrp {
  background-color: #1c1c1c;
}
.lightMode .tskComntInput {
  color: #1c1c1c !important;
  border: 1px solid #1c1c1c;
  background-color: #ffffff;
}
.lightMode .tskComtSndBtn {
  color: #ffffff !important;
  padding: 0px 16px !important;
  background-color: #1c1c1c !important;
}
.lightMode .tskComtSndBtn svg {
  color: #ffffff !important;
}
.lightMode .taskElementGrp .progress {
  background-color: #cccccc;
}
.lightMode .progress-done.ps50,
.lightMode .progress-done.ps80,
.lightMode .progress-done.ps100 {
  background: linear-gradient(to left, #00a2ff, #30b4ff);
}
.lightMode .pwCount {
  color: #1c1c1c !important;
}
.lightMode .taskActBtn .MuiIconButton-root:hover {
  color: #1c1c1c !important;
  background-color: #cccccc;
}
.lightMode .elemntFilNm {
  color: #707070 !important;
  opacity: 1;
}
.lightMode .elemntFile {
  border: 1px solid #b2b2b2;
}
.lightMode .elemntFile:hover {
  background-color: #dadada !important;
}
.lightMode .elemntFile:hover .elemntFilIoc svg {
  color: #707070 !important;
}
.lightMode .elemntMrFileBtn:hover {
  border: 1px solid #707070 !important;
  background-color: #dadada;
}

.lightMode .elemntMrFileBtn:hover svg {
  color: #1c1c1c !important;
}
.lightMode .docIcons {
  background: #cccccc;
}
.lightMode .langOption .formControl .MuiOutlinedInput-notchedOutline {
  border: 1px solid #cccccc !important;
}
.lightMode .taskVDContBtn .MuiButton-contained {
  background: #cccccc !important;
  border: 1px solid #a8a8a8 !important;
  color: #707070 !important;
  box-shadow: none;
}
.lightMode .taskVDContBtn .MuiButton-contained:hover {
  color: #1c1c1c !important;
  background: #b2b2b2 !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.lightMode .RightFlotingContainerArea {
  background-color: #f9f9f9;
}
.lightMode .taskTempaltesPannel {
  border-left: 0.1px solid #b2b2b2;
  background-color: #f9f9f9;
}
.lightMode .taskTemplatesContainer {
  background-color: #f9f9f9;
}
.lightMode .taskTempHeaderPanel {
  background-color: #f9f9f9;
  border-bottom: 1px solid #b2b2b2;
}
.lightMode .tskHedCon {
  color: #1c1c1c;
}

.lightMode button.moreTemButton,
.lightMode button.sugTemButton {
  background-color: #cccccc !important;
  border: 1px solid #a8a8a8;
  color: #707070 !important;
}

.lightMode button.moreTemButton:hover,
.lightMode button.sugTemButton:hover {
  color: #1c1c1c !important;
  background-color: #b2b2b2 !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.lightMode .morTaskSearch .searchUser {
  background: #ffffff;
  border: 1px solid #707070;
}
.lightMode .morTaskSearch .serchInput {
  color: #1c1c1c;
}
.lightMode .moreTemp,
.lightMode .sugestTemp {
  border-bottom: 1px solid #b2b2b2;
}
.lightMode .viewAllSugTemp span {
  color: #1c1c1c;
}

.lightMode .moreTemFlNm,
.lightMode .sugTemFlNm {
  color: #1c1c1c;
}
.lightMode .fltrdTgClose {
  background: #cccccc;
}
.lightMode .fltrdTgClose svg {
  fill: #1c1c1c;
}
.lightMode .goNextBtn,
.lightMode .goPrevousBtn {
  width: 37px;
  color: #707070 !important;
}
.lightMode .goNextBtn:hover,
.lightMode .goPrevousBtn:hover {
  color: #1c1c1c !important;
}
/* .lightMode .elementFileListedGrp .horaizonScroll {
  width: calc(100% - 41px) !important;
  min-width: calc(100% - 41px);
  max-width: calc(100% - 41px);
}
.lightMode .elementFileListedGrp .horaizonScroll .width100p {
  width: calc(100% - 88px) !important;
  min-width: calc(100% - 88px);
  max-width: calc(100% - 88px);
} */
.lightMode .tab-content {
  background: #ffffff !important;
}
.lightMode .bottomMnuPopover {
  background-color: #e0e0e0 !important;
  max-width: 555px;
}
.lightMode .bottomMnuPopoverContainer {
  min-width: 470px;
  max-width: 470px;
  background-color: #e0e0e0 !important;
  color: #545454 !important ;
}
.lightMode .hedUpFils {
  color: #333;
}
.lightMode button.srchMrTempBtn {
  background-color: #545454 !important;
  color: #ffffff !important;
}
.lightMode button.srchMrTempBtn:hover {
  background-color: #1c1c1c !important;
}
.lightMode .dfultPrimaryBtn {
  background-color: #707070 !important;
  color: #fff !important;
  border: 1.6px solid #707070 !important;
}
.lightMode .dfultPrimaryBtn:hover {
  background-color: #1c1c1c !important;
  color: #fff !important;
  border: 1.6px solid #1c1c1c !important;
}
.lightMode .dfultDarkSecondaryBtn {
  border: 1.6px solid #707070 !important;
  color: #707070 !important;
}
.lightMode .dfultDarkSecondaryBtn:hover {
  border: 1.6px solid #1c1c1c !important;
  color: #ffffff !important;
  background-color: #1c1c1c !important;
}
.lightMode .uploadedFiles {
  color: #545454;
}
.lightMode .form-container {
  color: #545454;
}
.lightMode
  .form-container
  div
  .MuiButtonBase-root.MuiButton-root.MuiButton-contained {
  background-color: #545454 !important;
}
.lightMode .workUpdateBtnGrp .MuiButtonBase-root.MuiButton-root {
  color: #1c1c1c;
  background-color: #ececec;
}
.lightMode .workUpdateBtnGrp .activeStatus {
  background-color: #545454 !important;
  border: 1px solid #545454 !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  color: #e7e7e7 !important;
}
.lightMode .AnalyticsDashboardContainerArea {
  background-color: #ffffff;
}
.lightMode .AnalyticsDashboardHeader {
  color: #1c1c1c;
}
.lightMode .anDashCardArea {
  background-color: #f9f9f9 !important;
  border: 1px solid #ececec !important;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px !important;
}
.lightMode .andCrdNM {
  color: #1c1c1c !important;
}
.lightMode .metRightInfoContainer {
  background-color: #f9f9f9;
}
.lightMode .meetRightHeader {
  background: #f9f9f9 !important;
  border-bottom: 1px solid #b2b2b2 !important;
}
.lightMode .healthContainer .metRtTitle {
  color: #1c1c1c;
}
.lightMode .pDemogInd span.pDVlue {
  color: #333;
  font-weight: 500;
}
.lightMode .editInputVitals {
  border: 1px solid #b2b2b2;
  background-color: #f9f9f9;
  color: #333;
  box-shadow: none;
  outline: none;
}
.lightMode .editInputUnits {
  background-color: #f9f9f9;
  color: #333;
  box-shadow: none;
  outline: none;
}
.lightMode .MuiPaper-root.MuiAccordion-root.docInputElmntAcod {
  width: 100%;
  background-color: #e9e9e9;
  color: inherit;
}
.lightMode
  .docInputElmntAcod
  .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded {
  background: #cccccc;
}
.lightMode .docInputElmntAcod .acodHeader .tskElemHeding {
  color: #1c1c1c;
  opacity: 1;
}
.lightMode .formControl .MuiAutocomplete-root.formAutocompleteField {
  color: #333333 !important;
  background-color: #ffffff !important;
}
.lightMode
  .formElement
  .MuiAutocomplete-endAdornment
  .MuiButtonBase-root.MuiIconButton-root
  svg {
  color: rgb(0 0 0 / 60%);
}
.lightMode
  .formAutoComInputField
  input.MuiInputBase-input.MuiOutlinedInput-input {
  padding: 8px 32px 8px 12px;
  color: #333333;
}
.lightMode .formInputField {
  color: #333333 !important;
  background-color: #ffffff !important;
}
.lightMode .formControl .MuiSelect-select.MuiSelect-outlined {
  color: #333333 !important;
}
.lightMode .complstDtlGrp .removeItemBtn {
  background-color: #cccccc;
  color: #ff0d0d;
}
.lightMode .metRtAction .MuiButtonBase-root.MuiIconButton-root {
  color: #333;
}
.lightMode .slash {
  background-color: #545454;
}
.lightMode .formControl .MuiOutlinedInput-notchedOutline {
  border: 1px solid #b2b2b2 !important;
  color: #333;
}
.lightMode
  .formControl
  .MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementEnd {
  justify-content: flex-end !important;
  margin-left: 0px;
  color: #333;
}
.lightMode .MuiFormControlLabel-root.formRadioField svg {
  color: #545454;
}
.lightMode .phyExamTable,
.phyExamTable th,
.lightMode .phyExamTable td {
  padding: 2px 10px;
  vertical-align: middle;
  border: none;
  text-align: left;
  width: auto;
  color: #333;
  font-size: 14px;
  border: 1px solid #b2b2b2;
  border-collapse: collapse;
}
.lightMode .phyExamTable {
  padding: 0px !important;
  width: 100%;
}
.lightMode .phyExamTable th {
  background-color: #d2d2d2;
  color: #333;
  font-size: 14px;
  font-weight: 500;
  opacity: 1;
  padding: 10px;
}
.lightMode .hilightHeading {
  background-color: #b1b1b1;
  opacity: 1;
  border-radius: 0px;
}
.lightMode .docInputElmntAcod .acodDetails .tskElemHeding {
  color: #333333 !important;
  opacity: 1;
}
.lightMode .pastIllness .formElement .formDatePicker {
  color: #333;
  border: 1px solid #707070;
  background-color: #ffffff;
}
.lightMode
  .formControl
  .MuiFormLabel-root.MuiInputLabel-root.setCompletionDate {
  background-color: #ffffff;
}
.lightMode .mxW-150 {
  max-width: 135px !important;
}
.lightMode .testResulttable th {
  background-color: #d2d2d2;
  color: #333333;
  opacity: 1;
}
.lightMode .MuiButtonBase-root.MuiButton-root.differnDiagnosBtn {
  background: #cccccc;
  border: 1px solid #a8a8a8;
  color: #1c1c1c;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.lightMode .descptionTextFild .MuiInputBase-root.MuiOutlinedInput-root {
  background: #ffffff;
  color: #333333;
}
.lightMode .formElement .formDatePicker {
  color: #333333;
  border: 1px solid #b2b2b2;
  background-color: #ffffff;
}
.lightMode .MeetRightFooter {
  background-color: #f9f9f9 !important;
  border-top: 1px solid #b2b2b2 !important;
}
.lightMode .healthContainer .joinMetBtn {
  background-color: #707070 !important;
  color: #fff !important;
  border: 1.6px solid #707070 !important;
}
.lightMode .c-bmi__result {
  color: #333;
}
.lightMode .bb {
  border-bottom: 1px dashed #b2b2b2;
}
.lightMode .warnigMsg {
  font-size: 12px;
  color: #545454;
  margin-top: 6px;
}
.lightMode .vitalheadngtxt {
  color: #1c1c1c;
  opacity: 0.7;
}
.lightMode .vitalinfosubtxt {
  color: #1c1c1c;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.lightMode .vitlulinfo {
  color: #1c1c1c;
}
.lightMode .taskListtableHeader tr th {
  background-color: #d2d2d2;
  color: #333333;
}
.lightMode .mednam {
  color: #333;
}
.lightMode .taskListTable tbody tr:hover {
  background-color: #e6e6e6;
}
.lightMode .leftside .vitalscontainer .vitals {
  background-color: #dadada;
  border-bottom: 1px dashed #b2b2b2;
}
.lightMode .Btxt {
  color: #545454;
}
.lightMode .Biconarea svg {
  fill: #797979;
}
.lightMode .leftside .vitalscontainer .Bunitarea {
  background-color: #c2c2c2;
}
.lightMode .rate,
.lightMode .oxygn {
  color: #333333;
  letter-spacing: 1px;
}
.lightMode .feedBackContainerArea {
  background-color: #ffffff;
}
.lightMode .feedbackHeader {
  background-color: #cccccc;
  color: #1c1c1c;
}
.lightMode .feedBackCarea {
  color: #333333;
}
.lightMode .submitFeedbackBtn.MuiButtonBase-root.MuiButton-root {
  background-color: #1c1c1c;
  color: #fff;
}
.lightMode span.fbold {
  color: #333333 !important;
  font-size: 14px;
  font-weight: 600;
}
.lightMode .highlighted-text {
  color: #333333;
}
.lightMode .AnalyticsDashboardSubHeader {
  color: #1c1c1c;
}
.lightMode .tskLstTaskNM {
  color: #707070;
}
.lightMode .taskListTable tbody tr {
  box-shadow: inset 0 -1px 0 #b2b2b2;
}
.lightMode .searchTasklist {
  border: 1px solid #b2b2b2;
  background-color: #ffffff;
}
.lightMode .serchTaskInput {
  color: #333333;
}
.lightMode .searchTasklist .seacrhIcon {
  padding: 8px;
  height: 38px;
  width: 38px;
  color: #ffffff;
  background-color: #707070 !important;
  border-radius: 0px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.lightMode .tsktypCommnt {
  color: #1c1c1c;
}
.lightMode .tblActionBtnGrp .MuiButtonBase-root.MuiIconButton-root {
  color: #707070;
}
.lightMode .rightFlotingContainer {
  background-color: #f9f9f9 !important;
}

.lightMode .rfContHeader {
  background-color: #f9f9f9 !important;
  border-bottom: 1px solid #b2b2b2 !important;
}
.lightMode .rfcHeadText {
  color: #1c1c1c !important;
}
.lightMode .rightFlotingPanel {
  border-left: 0.1px solid #b2b2b2 !important;
  background-color: #000 !important;
}
.lightMode .dfultDarkSecondaryBtnSOS {
  color: #ff0000 !important;
}
.lightMode
  .doctorSelectWthSubBtn
  .formAutoComInputField
  input.MuiInputBase-input.MuiOutlinedInput-input {
  color: #333333;
}
.lightMode .filterPatBtn.MuiButtonBase-root.MuiIconButton-root {
  border: 1px solid #707070;
  background-color: #707070;
}
.lightMode .scanFaceBtn .MuiButtonBase-root.MuiIconButton-root {
  border: 1px solid #707070;
  background-color: #707070;
}
.lightMode select.countryCode {
  background-color: #707070;
}
.lightMode .pDemographyImgGrp .patImage {
  background-color: #cccccc;
}
.lightMode .meetingControlBtnGroup {
  background-color: #e0e0e0 !important;
}
.lightMode .meetContBtn button {
  background-color: #cccccc;
  color: #545454;
}
.lightMode .meetContBtn button:hover {
  background-color: #545454;
  color: #cccccc;
}
.lightMode .meetContBtn.metBtnActive button {
  background-color: #545454;
  color: #ff9900;
}
.lightMode .radial-progress-timer text {
  fill: #333333;
}
.lightMode .radial-progress-bg-timer {
  stroke: #cccccc;
}
.lightMode .playAudioNotes .MuiButtonBase-root.MuiIconButton-root {
  border: 1px solid #707070 !important;
  color: #ffffff !important;
  padding: 4px !important;
  background: #707070 !important;
}
.lightMode .playAudioNotes {
  color: #333333;
}
.lightMode .feedbackRatingChart svg g g.text-group text {
  fill: #333333 !important;
}
.lightMode .feedbackRatingChart .text-group {
  transform: translate(37%, 94%);
}
.lightMode .elementListGrp .elementHeding {
  color: #1c1c1c;
  opacity: 0.7;
}
.lightMode .elmListInfo li {
  background-color: #d1d1d1;
}
.lightMode .elementListGrp ul.elmListInfo {
  color: #1c1c1c;
}
.lightMode .elmListInfo .rmvBtn {
  color: #ff2424;
  opacity: 0;
}
.lightMode
  .formElement
  .formAutoComInputField
  .MuiButtonBase-root.MuiChip-root {
  background-color: #d1d1d1;
  color: #1c1c1c;
  height: 26px;
  margin-top: 8px;
}
.lightMode .MuiIconButton-root.openFullScrnIcon {
  height: 42px;
  width: 42px;
  background-color: #d1d1d1;
  border: 1px solid #cccccc;
}
.lightMode .MuiIconButton-root.openFullScrnIcon svg {
  color: #1c1c1c;
}

.lightMode .MuiButtonBase-root.MuiButton-root.indImgButton {
  background-color: #b2b2b2;
  color: #1c1c1c;
}
.lightMode .indImgButton::before,
.lightMode .indImgButton::after {
  border: 2px solid #1c1c1c;
}

.lightMode .indImgButton::after {
  top: 0;
  left: 0;
  border-top-left-radius: 6px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: none;
  border-right: none;
}
.lightMode .indImgButton::before {
  bottom: 0;
  right: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 6px;
  border-top: none;
  border-left: none;
}
.lightMode .filterPopperBox {
  background-color: #ffffff;
  border: 1px solid #b2b2b2;
  color: #333333;
}
.lightMode .filterPopper {
  box-shadow: 0 10px 27px -6px #1c1c1c42;
}

.lightMode .filterPatlistInd {
  display: flex;
  align-items: center;
  gap: 10px;
  width: calc(100% - 16px);
  padding: 4px 8px;
  background-color: #f1f1f1;
  border-radius: 4px;
  border-bottom: 1px dashed #b2b2b2;
  cursor: pointer;
  margin-bottom: 4px;
}
.lightMode .filterPatlistInd:hover {
  background-color: #cccccc;
}
.lightMode .filPatImg img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  object-fit: cover;
  background-color: #cccccc;
}

.lightMode .editInputDate {
  padding: 4px;
  border: 1px solid #b2b2b2;
  background-color: #ffffff;
  color: #1c1c1c;
  border-radius: 4px;
  font-family: inter;
  outline: none;
  box-shadow: none;
}
.lightMode .tskLstTaskPriority {
  background-color: #ececec;
  color: #333333;
}
.lightMode .taskContainerArea {
  background-color: #ffffff;
}

.lightMode .taskListHeader {
  color: #1c1c1c;
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  cursor: pointer;
}
.lightMode .inOutIcon svg path {
  fill: #848484;
}
.lightMode .tskLstTaskTypeNm {
  color: #707070;
  font-size: 14px;
  font-family: "Inter";
}

.lightMode .tskLstTaskNM .tskListTskName {
  color: #707070;
  opacity: 1;
}

.lightMode .tskLstTaskDescription {
  color: #707070;
  opacity: 1;
}
.lightMode .tskLstTaskRequstBy,
.lightMode .tskLstTaskCompDt,
.lightMode .tskLstTaskStatus,
.lightMode .tskLstTaskContract {
  color: #707070;
}

.lightMode .taskListTable tbody tr.trowSelected {
  background-color: #ececec;
}
.lightMode ol.listOfLinkTask {
  color: #1c1c1c;
}
.lightMode .listOfLinkTask .linkTskInd {
  color: #333333;
}
.lightMode .listOfLinkTask li.linkTskInd:before {
  color: #1c1c1c;
  opacity: 0.7;
}
.lightMode .linkTskNm:hover {
  letter-spacing: 0.5px;
  color: #000000;
}
.lightMode .createTaskContainerArea {
  background-color: #ffffff;
}
.lightMode .crtTaskHeader {
  color: #1c1c1c !important;
}
.lightMode .activeStatus {
  background-color: #a8a8a8 !important;
  color: #1c1c1c !important;
  border: 1px solid #707070 !important;
}
.lightMode .chatConversationPannel {
  border-left: 0.1px solid #b2b2b2 !important;
  background-color: #ffffff !important;
}
.lightMode .chatLftContHead {
  background: #ffffff !important;
}
.lightMode .inChatAct svg {
  color: #545454 !important;
}
.lightMode .chatCnctLstCont {
  background-color: #ececec !important;
}
.lightMode .userDtlWlMsg {
  border-bottom: 0.5px solid #b2b2b2 !important;
}
.lightMode .chatContainerArea .searchUser {
  background: #ececec !important;
  border: 1px solid #b2b2b2 !important;
  width: calc(100% - 20px);
}
.lightMode .chatContactList:hover,
.lightMode .chatContactList.activeChat {
  background-color: #cccccc !important;
}
.lightMode .ctCnvUNm {
  color: #1c1c1c !important;
}
.lightMode .ctCnvUDeg {
  color: #545454 !important;
}
.lightMode .chatConvoHeader {
  max-height: 62px;
  border-bottom: 1px solid #b2b2b2 !important;
  background: #ffffff !important;
}
.lightMode .chatConvoContainer {
  background-color: #cccccc !important;
}
.lightMode .chatConvstionConArea::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(233 233 233 / 92%) !important;
  z-index: 1;
}
.lightMode .chatConvoMsg {
  z-index: 2;
  position: relative;
}
.lightMode .msg-in {
  float: left;
  border-radius: 0px 8px 8px 8px;
  background-color: #ffffff !important;
  color: #1c1c1c;
  margin-left: 6px;
}
.lightMode .msg-in::before {
  content: "";
  position: absolute;
  left: -16px;
  top: 0;
  width: 0;
  height: 0;
  border-left: 16px solid transparent;
  border-right: 0px solid transparent;
  border-top: 9px solid #ffffff !important;
  clear: both;
}
.lightMode .msg-out {
  float: right;
  border-radius: 8px 0px 8px 8px;
  background-color: #dadada !important;
  color: #1c1c1c !important;
  margin-right: 10px;
}
.lightMode .msg-out::before {
  content: "";
  position: absolute;
  right: -16px;
  top: 0;
  width: 0;
  height: 0;
  border-left: 16px solid #dadada !important;
  border-right: 0px solid transparent;
  border-bottom: 9px solid transparent;
  clear: both;
}
.lightMode .msgTxt {
  color: #1c1c1c;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  word-break: break-word;
}
.lightMode .msgDT {
  color: #747474;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.lightMode .chtMsg .msg:hover .chtCovAct {
  opacity: 1;
  background: radial-gradient(circle farthest-side, #b6b6b6b8, #ffffff00);
}
.lightMode .chtCovAct {
  position: absolute;
  top: 1px;
  z-index: 100;
  color: #1c1c1c !important;
  opacity: 0;
  right: 1px;
  height: 24px;
  width: 24px;
  cursor: pointer;
  transition: right 75ms ease-out, top 75ms ease-out;
}
.lightMode .chtCovActMenu .MuiPaper-root.MuiPaper-elevation {
  background-color: #ffffff !important;
  color: rgb(53 53 53 / 87%);
  box-shadow: 0px 4px 22px -1px #00000017;
}
.lightMode .chatConvoFooter {
  min-height: 50px;
  background-color: #ffffff !important;
  order: 3;
}
.lightMode .chtRplyMsgDtlGrp {
  flex: 1 1;
  order: 1;
  background-color: #ececec !important;
  color: #1c1c1c;
  border-radius: 4px;
  padding: 6px 10px;
  border-left: 4px solid #707070 !important;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.lightMode .chtRplyMsgInfo {
  font-size: 14px;
  line-height: 18px;
  color: #1c1c1c !important;
  font-weight: 400;
  word-break: break-all;
}
.lightMode .chatInputBox input {
  border-radius: 8px;
  background: #ececec !important;
  height: 30px;
  width: calc(100% - 40px);
  border: none;
  padding: 2px 20px;
  color: #1c1c1c !important;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.2px;
  border: 1px solid #b2b2b2 !important;
}
.lightMode .chatInputBox input:focus-visible {
  outline: 0.5px solid rgb(178, 178, 178) !important;
  outline-color: rgb(178, 178, 178) !important;
  outline-style: solid;
  outline-width: 0.5px;
}
.lightMode .chatInputEmoje svg,
.lightMode .chatInputAttachment svg,
.lightMode .chatInputAudio svg {
  height: 24px;
  width: 24px;
  color: #1c1c1c !important;
}
.lightMode .activeBtn {
  background-color: #d3d3d3 !important;
  border: 1px solid #b2b2b2 !important;
  box-shadow: 1px 2px 6px -2px #b2b2b2 !important;
}
.lightMode .inChatAct .activeBtn svg {
  color: #1c1c1c !important;
}
.lightMode .newChatGrpPanel .chatLftContHead {
  max-height: 46px;
  font-size: 16px;
  color: #333333 !important;
  height: 100%;
}
.lightMode .serchInput {
  color: #1c1c1c !important;
}
.lightMode .usrNmIn {
  color: #1c1c1c !important;
}
.lightMode .usrNmIn svg {
  height: 18px;
  width: 18px;
  color: #666666 !important;
  margin-left: 5px;
}
.lightMode .lstMsgShw {
  color: #707070 !important;
}
.lightMode .contUserImg img {
  border: 1px solid #a8a8a8 !important;
  background-color: #dbdbdb !important;
}
.lightMode .msgDTIn {
  color: #888888 !important;
}
.lightMode .chatConvoUsrImg img {
  border: 1px solid #a8a8a8 !important;
  background-color: #dbdbdb !important;
}
.lightMode .crtBtnGrp:hover {
  background-color: #cccccc !important;
}
.lightMode .crtBtnGrp {
  color: #1c1c1c !important;
}
.lightMode .newChatGrpPanel .chatContactList:first-child .userDtlWlMsg {
  border-top: 0.5px solid #b2b2b2 !important;
}
.lightMode .grpCretFooter {
  background-color: #ffffff !important;
}
.lightMode .cretGrpButton {
  background-color: #1c1c1c !important;
  color: #fff !important;
}
.lightMode .slctedChtUser {
  background-color: #e0e0e0 !important;
  border-radius: 4px;
  border: 1px solid #cccccc !important;
}
.lightMode .scuRmv {
  color: #4b4b4b !important;
}
.lightMode .scuNm {
  color: #1c1c1c !important;
}
.lightMode .chtGrpNmTextFild input {
  color: #1c1c1c !important;
}
.lightMode .chtGrpNmTextFild label {
  color: #545454 !important;
}
.lightMode .chtGrpNmTextFild .MuiInput-underline:after {
  border-bottom-color: #545454;
}
.lightMode .upImgBigView img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  background-color: #b2b2b2 !important;
}
.lightMode .chatAtchImgArea {
  flex: 1 1;
  order: 2;
  position: relative;
  background-color: #cccccc;
  overflow: hidden;
  overflow-y: overlay;
}
.lightMode .imgDisWdth,
.lightMode .imgDismisIoc {
  width: 60px;
  color: #1c1c1c;
}
.lightMode .uplodMorImg {
  border: 1px solid #969696 !important;
}
.lightMode .uplodMorImg svg {
  color: #1c1c1c;
}
/* .lightMode .atchImgPlcHld:hover .remvImg {
  opacity: 1;
  background: radial-gradient(circle farthest-side, #626262, #0045361c);
}
.lightMode .remvImg {
  color: #1c1c1c;
} */

.lightMode .atchImgPlcHld {
  border: 1px solid #969696;
  background-color: #b2b2b2;
}

.lightMode .atchImgPHActive {
  border: 3px solid #009d7b;
}
.lightMode .editTimelineBtn.MuiButtonBase-root.MuiIconButton-root svg {
  color: #707070;
}
.lightMode .editTimelineBtn.MuiButtonBase-root.MuiIconButton-root {
  background-color: #cccccc;
  color: #3a3a3a;
}
.lightMode .meetingCardContainer {
  border-bottom: 1px solid #cccccc;
}
.lightMode .meetingCardContainer:hover {
  background-color: #f9f9f9;
}
.lightMode .meetInOutIoc svg path {
  fill: #000000;
}

.lightMode .inOutIcon .observerIcon svg path:nth-child(3),
.lightMode .inOutIcon .observerIcon svg path:nth-child(2) {
  stroke-width: 1.4;
  stroke: #ffffff;
}
.lightMode .meetTitle {
  color: #1c1c1c;
}

.lightMode .meetDT {
  color: #333333;
}
.lightMode .metConvImg {
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 32px;
  position: relative;
}
.lightMode .metConvImg img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border: 1.6px solid #545454;
  border-radius: 26px;
}
.lightMode .metConvName {
  color: #333333;
}
.lightMode .lnkTxt {
  color: #1c1c1c;
}
.lightMode .metngVEHeaderPnl {
  background-color: #f9f9f9;
  border-bottom: 1px solid #b2b2b2;
}
.lightMode .meetingVEContainer {
  background-color: #f9f9f9;
}
.lightMode .meetingsViewEditPannel {
  border-left: 0.1px solid #b2b2b2;
  background-color: #f9f9f9;
}
.lightMode .meetTitle_Time .meetTitle {
  color: #1c1c1c;
}
.lightMode .meetTitle_Time .meetDTime {
  color: #333333;
}
.lightMode .metGustDtlElementTitle,
.lightMode .metDtlElementTitle {
  color: #1c1c1c;
  opacity: 0.7;
}
.lightMode .metDtlElementDtil {
  color: #1c1c1c;
}
.lightMode .metGstName {
  color: #333333;
}
.lightMode .metAgendaElementTitle {
  color: #1c1c1c;
  opacity: 0.7;
}
.lightMode .metVEActBtn .MuiIconButton-root:hover {
  color: #1c1c1c;
  background-color: #cccccc;
}
.lightMode .eventAlertWrapper {
  background-color: #1c1c1c;
  box-shadow: 0 3px 16px -11px #1c1c1c;
}
.lightMode .evnAltIcon svg {
  height: 18px;
  width: 18px;
  color: #ffffff;
}
.lightMode .metVEContBtn .MuiButton-contained {
  background: #cccccc;
  border: 1px solid #a8a8a8;
  color: #707070;
  box-shadow: none;
}
.lightMode .evnDtlWTime {
  color: #fff;
}
.lightMode .eveAltBtn button {
  border: 1px solid #a8a8a8 !important;
  color: #cccccc !important;
}
.lightMode .eveAltBtn button:hover {
  border: 1px solid #d8d8d8 !important;
  color: #ffffff !important;
  background-color: #545454;
}
.lightMode .metVEContBtn .MuiButton-contained:hover {
  color: #1c1c1c;
  background: #b2b2b2;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.lightMode .metAgendaName {
  background: #ececec;
}
.lightMode .metAgendaNM {
  color: #545454;
}
.lightMode .metAgendaName:hover {
  background-color: #cccccc !important;
}
.lightMode .joinMetBtn {
  background-color: #707070 !important;
  color: #fff !important;
  border: 1.6px solid #707070 !important;
}
.lightMode .joinMetBtn:hover {
  background-color: #1c1c1c !important;
  color: #fff !important;
  border: 1.6px solid #1c1c1c !important;
}
.lightMode .metAgendaMrFileBtn {
  border: 1px solid #b2b2b2 !important;
}

.lightMode .metAgendaMrFileBtn:hover {
  border: 1px solid #b2b2b2 !important;
  background-color: #cccccc;
  color: #1c1c1c;
}

.lightMode .metAgendaMrFileBtn:hover svg {
  color: #1c1c1c !important;
}
.lightMode .msgCardContainer {
  border-bottom: 1px solid #cccccc;
}

.lightMode .msgCardContainer:hover {
  background-color: #f9f9f9;
}
.lightMode .msgInOutIoc svg path {
  fill: #1c1c1c;
}
.lightMode .senderName {
  color: #1c1c1c;
}
.lightMode .sendTime {
  color: #545454;
}
.lightMode .msgCardContainer .msgSendCommnt {
  color: #1c1c1c;
}
.lightMode .dashboardChatContainer .chatFrwUsrLstHeader,
.lightMode .dashboardChatContainer .chatGrpDtlHeader,
.lightMode .dashboardChatContainer .chatConvoHeader {
  background-color: #ffffff;
  border-bottom: 1px solid #b2b2b2;
}
.lightMode .chatConvoUserActIcon svg {
  color: #797979;
}
.lightMode .dashboardChatContainer .grpCretFooter,
.lightMode .dashboardChatContainer .chatConvoFooter {
  background-color: #ffffff;
  border-top: 1px solid #b2b2b2;
}
.lightMode .medtTitle {
  color: #1c1c1c !important;
}
.lightMode .toDateInput,
.lightMode .frmDateInput {
  background-color: #ffffff;
  border: 1px solid #b2b2b2;
}
.lightMode .medDateTime {
  color: #1c1c1c;
}
.lightMode .frmDaysPick {
  color: #545454;
}
.lightMode .frmdatePick {
  color: #1c1c1c;
}
.lightMode .slctFrmTime {
  background-color: #ffffff !important;
  color: #1c1c1c !important;
  border: 1px solid #b2b2b2 !important;
}
.lightMode .meetGustDtlElement .MuiAutocomplete-root {
  background-color: #ffffff;
  border-radius: 4px;
  color: #1c1c1c;
  border: 1px solid #b2b2b2;
}
.lightMode #participantSelect {
  color: #1c1c1c;
}
.lightMode .editModeOn {
  color: #ff5722 !important;
}
.lightMode .metngVEFooterPnl {
  border-top: 1px solid #b2b2b2;
}
.lightMode .schedulerContainerArea,
.lightMode .meetingsTabContainerArea {
  background-color: #ffffff;
}
.lightMode .bgWekOff {
  background-color: #cccccc !important;
  border-color: #b2b2b2 !important;
}
.lightMode .e-schedule .e-vertical-view .e-work-cells {
  border-color: #b2b2b2;
}
.lightMode .SchedulerHeader {
  color: #1c1c1c;
}
.lightMode .schedulerTopBtnGroup .MuiButtonGroup-root.MuiButtonGroup-contained {
  background-color: #ececec;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.08),
    0px 2px 2px 0px rgba(0, 0, 0, 0.05), 0px 1px 5px 0px rgba(0, 0, 0, 0.06);
  border: 1px solid #e0e0e0;
}
.lightMode
  .schedulerTopBtnGroup
  .MuiButtonGroup-root.MuiButtonGroup-contained
  .MuiButtonBase-root.MuiIconButton-root {
  color: #707070;
}

.lightMode
  .schedulerTopBtnGroup
  .MuiButtonGroup-root.MuiButtonGroup-contained
  svg {
  color: #707070;
}
.lightMode .formSingBtn .MuiButtonBase-root.MuiButton-root {
  color: #707070;
  background-color: #ececec;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.08),
    0px 2px 2px 0px rgba(0, 0, 0, 0.05), 0px 1px 5px 0px rgba(0, 0, 0, 0.06);
  border: 1px solid #e0e0e0;
}
.lightMode
  .schdulerTopBtnLeft
  .formDatePick
  .react-datepicker-wrapper
  .react-datepicker__input-container
  input {
  height: 40px;
  outline: none;
  padding-block: 0px;
  padding: 0px 32px 0px 16px;
  border-radius: 4px;
  color: #707070;
  background-color: #ececec;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.08),
    0px 2px 2px 0px rgba(0, 0, 0, 0.05), 0px 1px 5px 0px rgba(0, 0, 0, 0.06);
  border: 1px solid #e0e0e0;
  font-size: 16px;
  font-family: Inter;
  width: calc(200px - 48px);
}
.lightMode .legLabel {
  color: #333333;
}
.lightMode .CalendarBtnViewGroup .MuiButtonBase-root.MuiButton-root {
  background-color: #ececec !important;
  color: #707070;
  text-transform: capitalize !important;
  transition: all 0.6s;
}

.lightMode .CalendarBtnViewGroup .MuiButtonBase-root.MuiButton-root.activeBtn {
  background-color: #b2b2b2 !important;
  color: #1c1c1c;
}
.lightMode .CalendarBtnViewGroup .MuiButtonBase-root.MuiButton-root:hover {
  background-color: #cccccc !important;
}
.lightMode .CalendarBtnViewGroup .MuiButtonBase-root.MuiButton-root.activeBtn {
  background-color: #b2b2b2 !important;
  color: #1c1c1c;
}

.lightMode .e-schedule .e-vertical-view .e-date-header-wrap table tbody td {
  background-color: transparent;
  border-color: #b2b2b2;
  border-style: solid;
  border-width: 0 0 1px 1px;
  color: rgb(28 28 28 / 86%);
  text-align: left;
}
.lightMode .cell_Date {
  color: #333333;
}
.lightMode .cell_Days {
  color: #545454;
}
.lightMode .markAttendanceIcon {
  color: #545454 !important;
}
.lightMode .date-text .MuiButtonBase-root.MuiIconButton-root {
  color: #545454 !important;
}
.lightMode .e-schedule .e-vertical-view .e-time-cells-wrap table td {
  border-color: transparent;
  border-style: solid;
  border-width: 0 1px 1px 0;
  color: #1c1c1c;
  background-color: transparent;
}
.lightMode .facHeader {
  color: #1c1c1c;
}
.lightMode .SchduleRightFlotingContainer {
  background-color: #f9f9f9;
}
.lightMode .taskTitlePatId {
  color: #1c1c1c;
}
.lightMode .CloseBtn {
  color: #545454 !important;
  padding: 4px !important;
}
.lightMode
  .formElement
  .MuiInputBase-root.MuiFilledInput-root
  .MuiInputBase-input.MuiFilledInput-input {
  background: #ffffff;
  border-radius: 8px;
  color: #1c1c1c;
  padding: 10px 10px;
  width: 100%;
  font-size: 20px;
  line-height: 26px;
  border: 1px solid #b2b2b2;
}
.lightMode .convTimeCheckbix {
  color: #333333;
  display: flex;
  gap: 3px;
  cursor: pointer !important;
}

.lightMode .e-schedule .e-agenda-view .e-subject {
  color: #1c1c1c;
}
.lightMode .e-schedule .e-agenda-view .e-date-time {
  color: #797979;
}
.lightMode .e-schedule .e-agenda-view .e-day-border {
  border: 1px solid #b2b2b2;
}
.lightMode .e-schedule .e-agenda-view .e-content-wrap table td:first-child,
.e-schedule .e-agenda-view .e-date-column {
  border-color: #b2b2b2;
}
.lightMode .e-schedule .e-vertical-view .e-left-indent {
  border-color: #b2b2b2;
}
.lightMode .rightTimeCell {
  border-right: 1px solid #b2b2b2;
}
.lightMode .e-time-cells.e-time-slots {
  border-bottom-color: #b2b2b2 !important;
}
.lightMode .e-schedule .e-date-header-wrap {
  border-color: #b2b2b2;
}
.lightMode .e-schedule .e-month-view .e-date-header-wrap table td,
.lightMode .e-schedule .e-month-agenda-view .e-date-header-wrap table td {
  background-color: #a1a1a1;
  border-color: #b2b2b2;
  color: #1c1c1c;
}

.lightMode .e-schedule .e-month-view .e-work-cells,
.lightMode .e-schedule .e-month-agenda-view .e-work-cells {
  background-color: #ffffff;
  border-color: #b2b2b2;
  color: #333333;
}
.lightMode .e-schedule .e-month-view .e-other-month,
.lightMode .e-schedule .e-month-agenda-view .e-other-month {
  color: #1c1c1c !important;
}
.lightMode .e-schedule .e-month-view .e-date-header {
  color: #1c1c1c;
}
.lightMode .e-schedule .e-month-view .e-other-month .e-date-header {
  color: rgb(0 0 0 / 40%);
}
.lightMode .e-schedule .e-agenda-view .e-day-date-header {
  color: #1c1c1c;
}
.lightMode .schedule-overview.e-schedule .e-agenda-view .days-text {
  color: #333333;
}
.lightMode .e-appointment-details.moreEvent {
  background-color: #545454 !important;
  border: 1px solid #b2b2b2;
}

.lightMode .SchduleRightFlotingContainerSettings {
  background-color: #f9f9f9;
  border-left: 1px solid #dbdbdb;
}
.lightMode .control-panel {
  color: #1c1c1c !important;
}
.lightMode
  .right-panel
  .control-panel
  .MuiInputBase-root.MuiInput-root.MuiInput-underline {
  color: #1c1c1c !important;
  background-color: #ffffff;
  border: 1px solid #b2b2b2;
}
.lightMode .MuiSvgIcon-root.MuiSelect-icon {
  color: rgb(31 31 31 / 60%);
}
.lightMode
  .right-panel
  .control-panel
  .MuiInputBase-root.MuiInput-root.MuiInput-underline::before {
  border-bottom: none;
}

.lightMode
  .right-panel
  .control-panel
  .MuiInputBase-root.MuiInput-root.MuiInput-underline
  .MuiSelect-select.MuiSelect-standard {
  background-color: #ffffff;
}
.lightMode .crtElmGrpTitle {
  color: #333;
}
.lightMode .crtElmGrp {
  color: #333333;
}
.lightMode .website-status-card {
  background-color: #ffffff;
}
.lightMode .todayIndc {
  color: #1c1c1c;
  opacity: 1;
}
.lightMode .listOfDataConst ul li .lstDataInd .lstValue {
  font-size: 12px;
  color: #fff;
  font-weight: 500;
  flex: 1 1;
  padding: 8px 8px;
  cursor: pointer;
}

.lightMode .listOfDataConst ul li .lstDataInd .lstName {
  font-size: 12px;
  color: #1c1c1c !important;
  font-weight: 500;
  flex: 30% 1;
}

.lightMode .insideAnDashboard .leftMenu {
  background-color: #f9f9f9;

  border: 1px solid #b2b2b2;
}

.lightMode li.indSideMenu {
  padding: 10px;
  cursor: pointer;
  transition: all 0.6s;
  margin-bottom: 10px;
  border-radius: 6px;
  color: #545454;
  background-color: #f0f0f0;
}
.lightMode li.activeMenuDashbord {
  background-color: #cccccc;
}
.lightMode .indSideMenu.activeMenuDashbord .indSideMenu {
  background-color: #d7d7d7;
}
.lightMode .menuName {
  color: #545454;
}
.lightMode li.indSideMenu:hover {
  background-color: #cccccc;
}
.lightMode li.activeSM {
  background-color: #b2b2b2;
}

.lightMode .activeSM {
  color: #000000 !important;
  position: relative;
}
.lightMode .activeSM::before {
  content: "";
  height: 100%;
  width: 4px;
  background-color: #30b4ff;
  left: 0;
  top: 0;
  position: absolute;
}
.lightMode li.indSideMenu.activeSM:hover {
  background-color: #b3b3b3;
}
.lightMode .detailsAnalyticTableContainer {
  background-color: #f9f9f9;
  border: 1px solid #b2b2b2;
}
.lightMode .organizationPosition .taskListTable th,
.lightMode .organizationPosition .taskListTable td {
  border: 0.5px solid #b2b2b2;
}
.lightMode .docStatOfline {
  background-color: #ffa4a4;

  box-shadow: #cbcbcbcf 0 -1px 7px 1px, inset #f61212 0 -1px 9px,
    #940000 0 2px 12px;
}
.lightMode .docStatOnline {
  background-color: #39cb58;
  box-shadow: #929292cf 0 -1px 7px 1px, inset #50b834 0 -1px 9px,
    #00af00 0 2px 12px;
}
.lightMode .aqacInd:first-child {
  border-top: 1px solid #afafaf;
  border-right: 1px solid #afafaf;
  width: calc(50% - -6px);
  background-color: #afafaf;
  cursor: pointer;
}
.lightMode .aqacInd:last-child {
  border-top: 1px solid #9a9a9a;
  width: calc(50% + 9px);
  background-color: #9f9f9f;
  cursor: pointer;
}
.lightMode .aqacGrp {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  margin-left: -9px;
  color: #1c1c1c;
  margin-bottom: -10px;
  margin-top: 2px;
}
.lightMode .searchTicketSupport {
  border: 1px solid #b2b2b2;
  background-color: #ffffff;
}
.lightMode .searchTicketSupport .MuiButtonBase-root.MuiIconButton-root svg {
  color: #1c1c1c;
}
.lightMode .RightFlotingContainerArea .supportContainer {
  color: #1c1c1c !important;
  border-left: 0.5px solid #b2b2b2;
}
.lightMode .ticket-details-body {
  background-color: #f1f1f1;
  border: 1px solid #cccccc;
}
.lightMode .ticket-row {
  border-bottom: 1px solid #cccccc;
}
.lightMode .ticket-row:last-child {
  border-bottom: none;
}
.lightMode .ticket-container .ticket-details p {
  /* font-size: 18px; */
  line-height: 1.6;
  margin: 6px 0;
}
.lightMode .ticket-label {
  color: #545454;
  font-weight: bold;
  font-size: 14px;
  flex: 1 1;
  text-align: left;
}
.lightMode .ticket-value {
  color: #1c1c1c;
  font-size: 14px;
}

.lightMode .ticketComments {
  border: 1px solid #b2b2b2;
  background-color: #f1f1f1;
}
.lightMode .supportSendMessage {
  flex: 1 1;
  border: none;
  outline: none;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: #fff;
  padding: 10px 10px 6px 22px;
  height: 28px;
  color: #1c1c1c;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid #b2b2b2;
}
.lightMode .supportIdLink {
  text-decoration: underline;
  color: #1635de !important;
}
.lightMode .ticket-container {
  color: #1c1c1c;
}
.lightMode .ticket-container .file-details {
  display: inline-block;
  background-color: #f1f1f1;
  border: 1px solid #b2b2b2;
  padding: 5px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}
.lightMode .driveContainerArea {
  background-color: #ffffff;
}

.lightMode .ReactTable .rt-thead .rt-resizable-header-content {
  color: #545454;
}
.lightMode .driveSearch .searchUser {
  border: 1px solid #b2b2b2;
  background-color: #ffffff;
}
.lightMode .driveSearch .serchInput input {
  color: #1c1c1c;
}
.lightMode .ReactTable .rt-tbody .rt-tr-group {
  border-bottom: 1px solid #b2b2b2;
}
.lightMode .ReactTable {
  border: 1px solid #b2b2b200;
  color: #707070;
}
.lightMode .drvfileModf svg,
.lightMode .drvfileOwner svg,
.lightMode .drvfileSize svg,
.lightMode .drvfileName svg {
  color: #888888;
}
.lightMode .ReactTable .rt-tbody .rt-tr-group:hover {
  background-color: #e2e2e2;
  color: #1c1c1c;
}

.lightMode .backDrivBtn button {
  padding: 4px;
  background-color: #e0e0e0;
  color: #1c1c1c;
}
.lightMode .backDrivBtn button svg {
  color: #1c1c1c;
}
.lightMode .backDrivBtn button:hover {
  background-color: #b2b2b2;
}
.lightMode .drvPerentFold {
  color: #797979;
}
.lightMode .drvActiveFold {
  color: #1c1c1c;
}

.lightMode.ReactTable .-pagination input,
.lightMode .ReactTable .-pagination select {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #e0e0e0;
  color: #1c1c1c;
  padding: 5px 7px;
  font-size: inherit;
  border-radius: 8px;
  font-weight: normal;
  outline-width: 0;
}
.lightMode .testNameH {
  font-size: 18px;
  color: #1c1c1c;
  font-weight: 600;
}

.lightMode .checkinTime {
  color: #707070;
  opacity: 1;
  font-size: 14px;
  font-weight: 400;
}
.lightMode .testElmGrp {
  color: #1c1c1c;
}
.lightMode .testUnit,
.lightMode .testNrmlRange .tstnRangeElm {
  color: #1c1c1c;
  opacity: 0.6;
  font-size: 12px;
  font-weight: 400;
}
.lightMode .adminContainerArea {
  background-color: #ffffff;
}
.lightMode .cuCardList .cardContent {
  background-color: #f9f9f9;
  box-shadow: 2px 3px 3px -1px #afafaf;
  color: #707070;
  border: 1px solid #b2b2b2;
}
.lightMode .CUcardTitle {
  color: #1c1c1c;
}
.lightMode .cuhederTxt {
  color: #1c1c1c;
}
.lightMode .AdminWorkSpace .hmTabActvIndcator {
  background: #1c1c1c;
}
.lightMode .taskVDContBtn .MuiButton-outlined:hover {
  border: 1px solid #4c4c4c;
  color: #1c1c1c;
}
.lightMode .AdminRightFlotingContainer {
  background-color: #f9f9f9;
}
.lightMode .flotingAreaContainer {
  border-left: 1px solid #b2b2b2;
}
.lightMode
  .formControl
  .MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementStart {
  color: #1c1c1c;
}
.lightMode .textWithOption .fromText {
  color: #1c1c1c;
}
.lightMode .TV_Container .level-1 {
  color: #1c1c1c;
}
.lightMode .organizationPosition .formInputSelect {
  color: #1c1c1c !important;
  background-color: #ffffff !important;
  border: 1px solid #b2b2b2;
}
.lightMode
  .organizationPosition
  .formInputSelect
  .MuiInputBase-root.MuiOutlinedInput-root {
  color: #1c1c1c !important;
}
.lightMode .txtWithBtn .MuiButtonBase-root.MuiIconButton-root {
  color: #1c1c1c;
  background-color: #ababab;
}
.lightMode .buttonContainerGrp .MuiButtonBase-root.MuiIconButton-root {
  color: #1c1c1c;
}
.lightMode .MuiButtonGroup-root.MuiButtonGroup-contained.buttonContainerGrp {
  background-color: #bcbcbc;
  border-radius: 18px;
  padding: 1px 6px;
}
.lightMode .organizationPosition tbody tr td .buttonContainerGrp {
  opacity: 0.7;
}
.lightMode .buttonContainerGrp .MuiButtonBase-root.MuiIconButton-root.remove {
  color: #e90006;
}
.lightMode .emptyRowMessage {
  color: rgb(41 41 41 / 60%);
}
.lightMode .couponStatusSwitch span.MuiFormControlLabel-label {
  color: #545454;
}
.lightMode .payrollEditor .tskElemHeding {
  color: #1c1c1c;
  opacity: 1;
}
.lightMode
  .AdminRightFlotingContainer
  .formElement
  .MuiFormControlLabel-root.formRadioField {
  color: #444444;
}
.lightMode
  .payrollEditor
  .MuiFormControl-root.MuiTextField-root.formTextFieldArea {
  background-color: #dbdbdb;
}
.lightMode .payrollEditor .MuiInputAdornment-root.prIA p.MuiTypography-root {
  color: #1c1c1c;
}
.lightMode .tdText {
  color: #707070;
}
.lightMode .MuiButtonBase-root.MuiIconButton-root.removeRowBtn {
  background-color: #eeeeee;
}
.lightMode
  .tblActionBtnGrp
  .MuiButtonBase-root.MuiIconButton-root.removeRowBtn {
  color: #5b5b5b;
}
.lightMode .elmListInfo li:hover {
  background-color: #bdbdbd;
}
.lightMode .expElemhead {
  color: #1c1c1c;
}
.lightMode .elementListGrp ul.elmListInfo.custlist {
  color: #1c1c1c;
}
.lightMode .expenditureContainer .elmListInfo.custlist li {
  background-color: transparent;
}
.lightMode .orgPText {
  color: #707070;
}
.lightMode .storeTimBar .progress {
  background-color: #828282;
}
.lightMode .curntStorCont {
  background-color: #eeeeee;
}
.lightMode .CurntStrData .curntStrgTxtlight {
  color: #1c1c1c;
}
.lightMode .textWhie {
  color: #1c1c1c !important;
}
.lightMode .curntDaImg span.cuStText {
  color: #707070;
}
.lightMode .accessPerSpan {
  color: #707070;
  background-color: #dadada;
}
.lightMode .selectTemplateContainerArea {
  background-color: #ffffff;
}
.lightMode .stHedText {
  color: #1c1c1c;
}
.lightMode .stSubText {
  color: #545454;
}
.lightMode .indTemplateCard {
  background-color: #f9f9f9;
  box-shadow: 0 2px 15px -9px #000;
  border: 1px solid #b2b2b2;
}
.lightMode .labelText {
  color: #1c1c1c;
}
.lightMode .evmTitle {
  color: #1c1c1c;
}
.lightMode ol.evmList {
  color: #707070;
}
.lightMode .itemName {
  color: #1c1c1c;
}
.lightMode .itemNMActionGrp .MuiButtonBase-root.MuiIconButton-root {
  background-color: #e7e7e7;
  color: #f21515;
  padding: 6px;
}
.lightMode .tskLstTaskNM.eclipTextlin2 {
  color: #707070;
}
.lightMode .acsConLstInd {
  background-color: #ececec;
  color: #1c1c1c;
}
.lightMode .profileheader {
  background-color: #f9f9f9;
  border-bottom: 1px solid #b2b2b2;
}
.lightMode .proheadNm {
  color: #1c1c1c;
}
.lightMode .profileAreaContainer {
  background-color: #f9f9f9;
}
.lightMode .flotingProfilecontainer {
  background-color: #f9f9f9;

  border-left: 1px solid #b2b2b2;
}
.lightMode .proUserName {
  color: #1c1c1c;
}
.lightMode .proUserSkills {
  color: #707070;
}
.lightMode
  .fromWithEdtUpldBtn
  .elemntIconBtn.MuiButtonBase-root.MuiIconButton-root {
  color: #545454;
  background-color: #e9e9e9;
}
.lightMode .profileFromContainer .formElement .formDatePicker:disabled,
.lightMode
  .profileFromContainer
  .formElement
  .formControl
  .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled {
  -webkit-text-fill-color: rgb(51 51 51);
  background: #e1e1e1 !important;
}
.lightMode
  .profileFromContainer
  .formElement
  .formControl
  .MuiFormLabel-root.MuiInputLabel-root.setCompletionDate {
  background-color: #ffffff;
}
.lightMode .signAvl {
  border: 1px solid #b2b2b2;
  background-color: #fff;
}
.lightMode .signPreviewWithStamp {
  background-color: #fff;
  border: 1px solid #b2b2b2;
}
.lightMode .radial-progress-bg {
  fill: none;
  stroke: #cccccc;
  stroke-width: 4;
}
.lightMode .radial-progress text {
  fill: #1c1c1c;
}
.lightMode .radial-progress-bar {
  stroke: #18a2ff;
}
.lightMode .drivInfoBtn {
  border: 1px solid #b2b2b2 !important;
  color: #292929 !important;
}
.lightMode .Meeting_Transcription .elementHeading {
  color: #1c1c1c !important;
  opacity: 0.7;
}
.lightMode .Meeting_Transcription .elmListInfo li {
  background-color: transparent;
}
.lightMode .Meeting_Transcription .elmListInfo li span {
  color: #1c1c1c !important;
}
.lightMode .transcripAutoComplete {
  background: #ffffff;
  border: 1px solid #b2b2b2;
  color: #1c1c1c;
}
.lightMode .transcripAutoComplete .MuiInputBase-input.MuiOutlinedInput-input {
  color: #1c1c1c;
}
.lightMode .transcripAutoComplete svg {
  color: #1c1c1c;
}
.lightMode .transcriptionContainArea {
  color: #1c1c1c;
}
.lightMode .transcriptionContainArea .MuiTableCell-root {
  color: #545454 !important;
  border-bottom: 1px solid #b2b2b2;
}
.lightMode .transcriptionContainArea .MuiTableCell-root svg {
  fill: #333333;
}

.lightMode .meetLangIndBtn {
  color: #1c1c1c;
  opacity: 0.6;
}
.lightMode .meetLangIndBtn:hover {
  opacity: 1;
  background-color: #cacaca;
}
.lightMode .tranLangSrchInput {
  background: #ffffff;
  color: #1c1c1c;
  border: 1px solid #b2b2b2;
}
.lightMode .participantsRT {
  color: #333 !important;
}
.lightMode .ReactTable .-pagination input,
.ReactTable .-pagination select {
  border: 1px solid #b2b2b2;
  background: #e0e0e0;
  color: #1c1c1c;
}
.lightMode .closeDilogMeetPart {
  color: #333333;
}
.lightMode .flotHeaderPanel {
  background-color: #f9f9f9;
  border-bottom: 1px solid #b2b2b2;
}
.lightMode .flotHedCon {
  color: #1c1c1c;
}
.lightMode .flottingContainer {
  background-color: #f9f9f9;
}
.lightMode .RightFlotingFormContainerArea .formElement {
  border-bottom: 1px solid #e9e9e9;
}
.lightMode .RightFlotingFormContainerArea {
  background-color: #f9f9f9;
  border-left: 1px solid #b2b2b2;
}
.lightMode .RightFlotingFormContainerArea .formElement .formDatePicker {
  color: #333333;
  border: 1px solid #b2b2b2;
  background-color: #ffffff;
}
.lightMode
  .RightFlotingFormContainerArea
  .formControl
  .MuiFormLabel-root.MuiInputLabel-root.setCompletionDate {
  background-color: #f9f9f9;
}
.lightMode .indentFormtable table tr th {
  background-color: #dddddd;
}

.lightMode .indentFormtable table th,
.lightMode .indentFormtable table td {
  border: 1px solid #b2b2b2;
  border-collapse: collapse;
  text-align: left;
  padding: 4px;
  color: #545454;
  height: 26px;
}
.lightMode .indtableResponsive table tbody tr:hover {
  background-color: #dddddd;
}
.lightMode .indentFormtable .MuiButtonBase-root.MuiIconButton-root {
  color: #535353;
  background-color: #cfcfcf;
  padding: 4px;
}
.lightMode .RightFlotingFormContainerArea .formTextFieldArea input {
  color: #1c1c1c !important;
  background-color: #ffffff !important;
}
.lightMode .tvText {
  color: #1c1c1c;
}
.lightMode .fw600 {
  font-weight: 700;
  color: #1c1c1c;
}
.lightMode .indentFormtable .MuiButtonBase-root.MuiIconButton-root svg {
  fill: #545454;
}
.lightMode .taskElementGrpRow .tskElemHeding {
  min-width: 155px;
  max-width: 244px;
}
.lightMode .jdkraViewItem {
  color: #1c1c1c;
}
.lightMode .JDKRAforPositionList {
  color: #333333;
}
.lightMode .MuiButtonBase-root.MuiButton-root.showJdKraBtn {
  background: #e9e9e9;
  border: 1px solid #b2b2b2;
  color: #333333;
}
.lightMode
  .matRatingComment
  .MuiFormControl-root.MuiTextField-root.descriptionTextFild
  .MuiInputBase-root.MuiOutlinedInput-root {
  color: #1c1c1c;
}
.lightMode .ratingWithTxt {
  background-color: #ebebeb;
}
.lightMode .gdkraBtnGrp .MuiButtonBase-root.MuiButton-root {
  background: #cccccc;
  border: 1px solid #b2b2b2;
  color: #707070;
}
.lightMode .gdkraBtnGrp .MuiButtonBase-root.MuiButton-root.activeKra,
.lightMode .gdkraBtnGrp .MuiButtonBase-root.MuiButton-root.activeJd {
  background: #1c1c1c;
  border: 1px solid #1c1c1c;
  color: rgb(255 255 255);
}
.lightMode .healthContainer .elemntFilNm {
  color: #707070;
  opacity: 1;
}
.lightMode .joinMetWthRmIDGrp {
  background-color: #ffffff;
  border: 1px solid #b2b2b2;
}
.lightMode .joinMetRmIdInputBox {
  background-color: #ffffff;
  color: #1c1c1c;
}
.lightMode .joinBtnRoomNo {
  background-color: #707070 !important;
}
.lightMode .joinMeetingBtnGroup .joinInsMetBtn {
  background-color: #707070;
  color: #ffffff;
}
.lightMode .botFlotingContainer {
  background-color: #f9f9f9;
}
.lightMode .msgUserNm {
  color: #707070;
}
.lightMode .msgBodyTxt {
  color: #1c1c1c;
  background-color: #e9e9e9;
  margin-top: 6px;
}
.lightMode .createTicketBtn {
  border: 1px solid #b2b2b2 !important;
  border-radius: 5px !important;
  color: #1c1c1c !important;
  margin-top: 8px !important;
}
.lightMode .botInputGrp {
  background-color: #ffffff;
  border: 1px solid #b2b2b2;
}
.lightMode .botComntInput {
  color: #1c1c1c;
}
.lightMode .botComtSndBtn {
  color: #1c1c1c !important;
  padding: 0px 11px !important;
}
.lightMode .doctorListSlot {
  background-color: #f9f9f9;
  border: 1px solid #b2b2b2;
}
.lightMode .doctorDetails {
  background-color: #e9e9e9;
}
.lightMode .doctorNmSpc .docName {
  color: #1c1c1c;
}
.lightMode .docEFL .eflText {
  color: #545454;
}
.lightMode .doctorNmSpc .docSpclty {
  color: #333333;
  opacity: 0.6;
}
.lightMode .docRatvalue .ratingvalue {
  color: #1c1c1c;
}
.lightMode .docRatCount {
  color: #333333;
  opacity: 0.6;
}

.lightMode .doctorDetailSlots .slot-box {
  background-color: #ffffff;
  border: 1px solid #e9e9e9;
}
.lightMode .doctorDetailSlots .slot-box:hover {
  background-color: #e9e9e9;
}
.lightMode .slot-box .slotInfo .slotTime {
  color: #1c1c1c;
}
.lightMode .pid,
.lightMode .date {
  color: #1c1c1c;
}
.lightMode .appoinBookPatList .filtPatDtl {
  color: #1c1c1c;
}
.lightMode .appoinBookPatList .filterPatlistInd.active {
  background-color: #c3c3c3;
}
.lightMode .bookedAppoinmentMsg {
  color: #1c1c1c;
}
.lightMode .day-box {
  background-color: #e9e9e9;
  border: 1px solid #b2b2b2;
}
.lightMode .day-box.active {
  background-color: #cccccc;
  border: 1px solid #b2b2b2;
  box-shadow: 5px 5px 15px #d0d0d0, -5px -5px 16px #ededed;
  position: relative;
}
.lightMode .ddHighTxt {
  font-size: 16px;
  letter-spacing: 1px;
  color: #1c1c1c;
  font-weight: 600;
}
.lightMode .ddSubTxt {
  font-size: 14px;
  color: #545454;
  font-weight: 400;
}
.lightMode .sdHtxt {
  color: #333333;
}
.lightMode .slot-box {
  background-color: #e9e9e9;
  border: 1px solid #cccccc;
}
.lightMode .slot-box:hover {
  background-color: #b6b6b6;
}
.lightMode .appoinmentBookingSlot .slot-box.active,
.lightMode .doctorDetailSlots .slot-box.active {
  background-color: #b6b6b6;
  border: 1px solid #b2b2b2;
}
.lightMode .bdtSlotInfo .bookingDateTime {
  font-size: 14px;
  color: #1c1c1c;
  opacity: 0.6;
}
.lightMode .bookedSlot .slotInfo .slotTime .date,
.lightMode .bookedSlot .slotInfo .slotTime .time {
  color: #1c1c1c;
}
.lightMode .days-header-actionBtn .MuiButtonBase-root.MuiIconButton-root {
  background-color: #e0e0e0;
  border: 1px solid #cccccc;
  color: #333;
}
.lightMode .TicketForm_ticketFormContainer__NVWeJ {
  background-color: #e9e9e9;
  color: #1c1c1c;
}
.lightMode .TicketForm_ticketFormContainer__NVWeJ h2 {
  color: #1c1c1c;
}
.lightMode
  .TicketForm_ticketFormContainer__NVWeJ
  .TicketForm_ticketSwitch__oJ6cn
  button {
  background-color: #a6a6a6;
  color: #1c1c1c;
  border: 1px solid #b2b2b2;
}
.lightMode
  .TicketForm_ticketFormContainer__NVWeJ
  .TicketForm_ticketSwitch__oJ6cn
  button.TicketForm_active__FNTLv {
  background-color: #1c1c1c;
  border-color: #1c1c1c;
  color: #ffffff;
  box-shadow: rgb(0 0 0 / 40%) 0px 8px 24px;
}
.lightMode .TicketForm_ticketFormContainer__NVWeJ input[type="text"],
.lightMode .TicketForm_ticketFormContainer__NVWeJ textarea {
  background-color: #ffffff;
  color: #1c1c1c;
  border: 1px solid #b2b2b2;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  width: calc(100% - 20px);
  font-family: "Inter";
}
.lightMode
  .TicketForm_ticketFormContainer__NVWeJ
  .TicketForm_uploadSection__2gV95
  input[type="file"] {
  background-color: #e9e9e9;
  color: #666666;
  padding: 10px;
  border-radius: 5px;
}
.lightMode .ReactTable .-pagination .-btn {
  color: rgb(0 0 0);
  background: rgb(18 18 18 / 10%);
}
.lightMode
  .drvfileActionBtnGrp
  .MuiButtonBase-root.MuiIconButton-root
  svg.MuiSvgIcon-root {
  color: #545454;
}
.lightMode .spaceDtSEFill,
.lightMode .spaceDtSEAvale {
  color: #545454;
}
.lightMode .toDateInput:hover,
.lightMode .frmDateInput:hover {
  background-color: #d4d4d4;
}
.lightMode .viewLeaveListTD .rt-thead.-filters .rt-th input {
  background-color: #ffffff;
  border: 1px solid #b2b2b2;
  height: 37px;
}
.lightMode .viewLeaveListTD .rt-tr.-padRow.-odd .rt-td {
  background-color: rgba(211, 211, 211, 0.3);
}
.lightMode .ReactTable.viewLeaveListTD {
  border: 1px solid #b2b2b2;
  color: #707070;
}
.lightMode .checkDays {
  color: #1c1c1c;
}
.lightMode .checkBoxDays .MuiButtonBase-root.MuiCheckbox-root {
  color: #545454;
}
.lightMode .weekDaysIndv {
  border-bottom: 1px dashed #b2b2b2;
}
.lightMode .checkAdd .MuiButtonBase-root.MuiIconButton-root {
  color: #fff;
  background-color: #777777;
}
.lightMode .andCrdUnitCount span {
  color: #555555;
}
.lightMode .doctor-infoBA {
  background-color: #e0e0e0;
}
.lightMode .docNameOther .doctorNmSpc .docName {
  color: #1c1c1c;
}
.lightMode .docNameOther .doctorNmSpc .docSpclty {
  color: #1c1c1c;
}
.lightMode .docNameOther .docEFL .eflText {
  color: #545454;
}
.lightMode .slot-box.booked {
  background-color: #b2b2b2;
}
.lightMode .slot-box.booked:hover {
  background-color: #afafaf !important;
}
.lightMode .patDtl {
  color: #333333;
}
.lightMode .bookedPatInfo .patId {
  font-size: 10px;
  color: #1c1c1c;
  opacity: 1;
}
.lightMode .bookedPatInfo .patAge {
  color: #1c1c1c;
  opacity: 1;
}
.lightMode .doclistCusSlider .slick-prev,
.lightMode .doclistCusSlider .slick-next {
  color: #1c1c1c;
  background: #cccccc;
}
.lightMode
  .days-header-actionBtn
  .MuiButtonBase-root.MuiIconButton-root.settingsChange {
  background-color: #a0a0a0;
  color: #333333;
  border: 1px solid #b2b2b2;
  padding: 4px;
  margin-left: 6px;
}
.lightMode .featuresLandingPage {
  background-color: #ffffff;
}
.lightMode .diagonal-background {
  background-color: #a6a6a6;
}
.lightMode .fetursHeading {
  color: #1c1c1c;
}
.lightMode .fetursSubtxt {
  color: #333333;
}
.lightMode .featuresDetailsPage {
  background-color: #ffffff;
}
.lightMode .zoyelFeatureList li a {
  color: #1c1c1c;
}
.lightMode .featuresDetailsSect:nth-child(odd) {
  background-color: #f1f1f1;
}
.lightMode .fethead {
  color: #1c1c1c;
}
.lightMode .fetText {
  color: #333333;
}
.lightMode .msgMediaTxt {
  color: #1c1c1c;
}
.lightMode .totalTaskCount {
  color: #1c1c1c;
  opacity: 0.8;
}
.lightMode .tskCatg {
  position: absolute;
  transform: rotate(270deg);
  color: #b2b2b2;
  background-color: #f9f9f9;
  font-size: 12px;
  font-weight: 400;
  left: -40px;
  top: 34%;
}
.lightMode .ttlTskInd {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 2px;
  color: #707070;
}
.lightMode .valCount {
  color: #747474;
  font-weight: 600;
}
.lightMode .valperc {
  background-color: #e4e4e4;
  padding: 2px 6px;
  border-radius: 14px;
}
.lightMode .unavailableText {
  color: #6a6a6a;
  opacity: 0.6;
}
.lightMode .checkTrash .MuiButtonBase-root.MuiIconButton-root {
  color: #ff0000;
  background-color: #777777;
}
.lightMode .listDocRequierd {
  color: #1c1c1c;
  font-size: 14px;
  font-family: Inter;
  width: 100%;
}
.lightMode .tableHeaderContainer table.attendTableHeader tbody th {
  border-color: #b2b2b2;
  color: rgb(32 32 32 / 87%);

  background-color: #e8e8e8;
}
.lightMode .weekend {
  background-color: #c3c3c3;
}
.lightMode .atndEmpLstTbTR:hover {
  background-color: #d2d2d2;
}
.lightMode .atndEmpLstTbTR {
  border-bottom: 1px solid #b2b2b2;
}
.lightMode .mt-sm-2 h6 {
  color: #1c1c1c;
}
.lightMode .attendance-icon {
  border-color: #b2b2b2;
  color: rgba(59, 59, 59, 0.87);
}
.lightMode .text-others svg {
  color: #1c1c1c;
}
.lightMode .dashAttendanceDT {
  color: #1c1c1c;
  background-color: #ffffff;
  border: 1px solid #b2b2b2;
}
.lightMode .hilight {
  background-color: #707070;
}
.lightMode .patDocIndCard {
  background-color: #f1f1f1;
  border: 1px solid #b2b2b2;
}
.lightMode .patDetails {
  background-color: #cccccc;
  color: #1c1c1c;
}
.lightMode .patDocDetails .docHead {
  color: #1c1c1c;
  opacity: 0.7;
}
.lightMode .patDocInv .invDtlInd,
.lightMode .docPrsGrp .prsDtl {
  display: flex;
  align-items: center;
  background-color: #e0e0e0;
  border: 1px solid #b2b2b2;
  border-radius: 6px;
  color: #1c1c1c;
  padding: 2px 6px;
  gap: 6px;
}
.lightMode .invDtlInd .MuiButtonBase-root.MuiIconButton-root,
.lightMode .prsDtl .MuiButtonBase-root.MuiIconButton-root {
  color: #1c1c1c;
  background-color: #c3c3c3;
  border: 1px solid #b2b2b2;
  padding: 6px;
}
.lightMode .faceRegTxt {
  font-size: 14px;
  color: #1c1c1c;
  opacity: 0.6;
}
.lightMode .indvInv.formElement {
  background-color: #e9e9e9;
}
.lightMode .atchFilWthfilNm .elemntFilNm {
  color: #1c1c1c;
}
.lightMode .attachedFileDtl .MuiButtonBase-root.MuiIconButton-root svg {
  color: #ff1c1c;
}
.lightMode .curntDaImg span svg {
  color: #6b6b6b;
}
.lightMode .stroageTable th {
  background-color: #e0e0e0;
}
.lightMode .stroageTable td,
.lightMode .stroageTable th {
  border: 1px solid #b2b2b2;
  color: #1c1c1c;
}
.lightMode .fetContImg img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  filter: none;
}
.lightMode .inputValue {
  color: #1c1c1c;
}
.lightMode .budgetList ul {
  color: #545454;
}
.lightMode .e-attendLib {
  color: #999999;
}
.lightMode .formElement .formDatePicker.onlyTime {
  color: #333333;
  border: 1px solid #b2b2b2;
  background-color: #ffffff;
}
.lightMode .shiftElemHeding {
  color: #1c1c1c;
  background-color: #e7e7e7;
}
.lightMode .shiftTitleWIndex {
  color: #333333;
}
.lightMode .MuiButtonBase-root.MuiIconButton-root.removeShiftBtn {
  background-color: #e5e5e5;
  color: #f71111;
}
.lightMode .shiftTimerGrp {
  color: #747474;
}
.lightMode .daysName {
  color: #1c1c1c;
}
.lightMode .WorkingDaysHours .formElementGrp {
  border-bottom: 1px solid #b2b2b2;
}
.lightMode
  .WorkingDaysHours
  .formElement
  .MuiSelect-select.MuiSelect-outlined.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: rgba(124, 124, 124, 0.6);
  cursor: no-drop;
}
.lightMode .pmsDtlInfoDescn,
.lightMode .pmsDtlInfoTitle {
  color: #1c1c1c;
}
.lightMode .epfEsiSetingsGrp .MuiButtonBase-root.MuiIconButton-root {
  background-color: #e0e0e0;
}
.lightMode .epfEsiSetingsGrp .MuiButtonBase-root.MuiIconButton-root svg {
  color: #545454;
}
.lightMode .salGropuHead.darkrow {
  background-color: #efefef !important;
}
.lightMode .dayName {
  font-size: 12px;
  color: #1c1c1c;
}
.lightMode .langChangeSelect .MuiSvgIcon-root.MuiSelect-icon {
  color: rgb(31 31 31 / 60%);
}
.lightMode .mobChatBack .MuiButtonBase-root.MuiIconButton-root {
  background: #cfcfcf;
  color: #1c1c1c;
}
.lightMode .taskVDContBtn .MuiButton-outlined {
  border: 1px solid #b2b2b2;
  color: #707070;
}
.lightMode .slctFrmTime:hover {
  background-color: #cccccc !important;
}
.lightMode .joinCallBtn button,
.lightMode .dismissCallBtn button {
  color: #ffffff !important;
}
.lightMode .attachedFileDtl {
  color: #545454;
}
.lightMode .faceIndview .faceText {
  color: #1c1c1c;
}
.lightMode .valuDateRow {
  color: #1c1c1c;
}
.lightMode .proUserRegFace .MuiButtonBase-root.MuiIconButton-root {
  color: #989898;
}
.lightMode .MuiPopper-root.MuiAutoconplete-popper {
  background-color: #ffffff !important;
  background: #fff !important;
  opacity: 1 !important;
  z-index: 9999 !important;
  position: absolute;
  background-color: "#fff";
  box-shadow: "0px 4px 6px rgba(0, 0, 0, 0.1)";
  z-index: 1300;
}
.lightMode .taskListTable tbody tr.highlitedTask {
  background-color: rgb(224 224 224);
}
.lightMode .taskHeadingNM {
  color: #1c1c1c;
}
.lightMode .tskCretdByElmnt {
  color: #1c1c1c !important;
}
.lightMode .patDocPrs {
  border-bottom: 1px dashed #b2b2b2;
}
.lightMode .msgTxt a {
  color: #1c1c1c;
}
.lightMode .tskUseComImg img {
  background-color: #e0e0e0;
}
.lightMode .forwardList ul .forwDtlGrp {
  color: #1c1c1c;
}
.lightMode .forwTskStatus {
  background-color: #b2b2b2;
  color: #1c1c1c;
}
.lightMode .frwUserDtlWthBtn {
  background-color: #e0e0e0;
}
.lightMode .forwardList ul .forwDtlGrp {
  background-color: #eeeeee;
}
.lightMode .moreMenu .MuiButtonBase-root.MuiIconButton-root {
  background-color: #cccccc;
  color: #050505;
}
.lightMode .cuCheckItem:hover {
  color: #1c1c1c;
}
.lightMode .tskElemntInfo {
  color: #1c1c1c;
}

.lightMode .multipleUpdateTaskCard {
  border-bottom: 0.5px solid #b2b2b2;
  width: 100%;
  padding: 2px 0px;
}
.lightMode .regimeName {
  color: #1c1c1c;
}
.lightMode .invAddedIndv {
  background-color: #cfcfcf;
}
.lightMode .invAddedIndv .invAddName {
  color: #333333;
}
.lightMode .invAddedIndv .MuiButtonBase-root.MuiIconButton-root {
  background-color: #b8b8b8;
}
.lightMode .invAddedIndv .MuiButtonBase-root.MuiIconButton-root svg {
  color: #1c1c1c;
}
.lightMode .regimeWithComponent ul.regimeList {
  color: #1c1c1c !important;
}
.lightMode .compOr .compChild,
.lightMode .compThen .compChild {
  color: #545454;
}
.lightMode .medRecStpInd {
  background-color: #e1e1e1;
}
.lightMode .meeting-summary {
  border: 1px solid #b2b2b2;
}
.lightMode .meeting-summary .left-section {
  border-right: 1px solid #b2b2b2;
}
.lightMode .meeting-summary .transcript,
.lightMode .meeting-summary .meeting-files,
.lightMode .meeting-summary .meeting-links,
.lightMode .meeting-summary .meeting-agenda {
  border: 1px solid transparent;
}

.lightMode .meeting-summary .meeting-tasks {
  border: 1px solid transparent;
}
.lightMode .transcripAutoCompleteView {
  background: #ffffff;
}
.lightMode .transcripAutoCompleteView .MuiInputBase-root.MuiOutlinedInput-root {
  border: 1px solid #b2b2b2;
}
.lightMode .meeting-summary .right-section {
  background-color: #f9f9f9;
}
.lightMode
  .transcripAutoCompleteView
  .MuiInputBase-input.MuiOutlinedInput-input {
  color: #1c1c1c;
}
.lightMode .task-card {
  background-color: #ffffff;
  border: 1px solid #b2b2b2;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 16px;
  width: calc((100% - 84px) / 2);
  display: flex;
  flex-direction: column;
  position: relative;
}
.lightMode .task-card p {
  color: #1c1c1c;
}
.lightMode .task-card strong {
  color: #1c1c1c;
}
.lightMode .MuiButtonBase-root.MuiIconButton-root.IconBtnTranslate {
  background-color: #d4d4d4;
  color: #1c1c1c;
}
.lightMode .poliongHand {
  color: #333333;
}
.lightMode .MuiButtonBase-root.MuiIconButton-root.downloadAgendaFile {
  color: #333333;
  background-color: #d4d4d4;
}
.lightMode .recMeetingsLinks {
  background-color: #d4d4d4;
  color: #1c1c1c !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.lightMode .meetFilesInd {
  background-color: #d4d4d4;
  color: #1c1c1c;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.lightMode .meetFilesInd .MuiButtonBase-root.MuiIconButton-root {
  color: #545454;
}
.lightMode .meeting-summary table,
.lightMode .meeting-summary th,
.lightMode .meeting-summary td {
  border: 1px solid #b2b2b2;
}
.lightMode .loadMoreSpn .MuiButtonBase-root.MuiButton-root {
  color: #1c1c1c;
  background-color: #e2e2e2;
  font-weight: 500;
}
.lightMode .filElmInd .MuiButtonBase-root.MuiIconButton-root {
  background-color: #f8f8f8;
  border: 1px solid #f1f1f1;
}
.lightMode .chatFrwUsrLstContainer,
.lightMode .chatGrpDtlContainer {
  background-color: #f9f9f9;
  border-left: 0.2px solid #b2b2b2;
}
.lightMode .filElmInd .MuiButtonBase-root.MuiIconButton-root {
  background-color: #f6f6f6;
  border: 1px solid #dedede;
}
.lightMode .ddContainer {
  color: #1c1c1c;
}
.lightMode .AdminRightFlotingContainer.ddFlotContainer {
  box-shadow: -9px 0 24px #7f7f7fe6;
}
.lightMode .regimeName .MuiFormControlLabel-root.formRadioField {
  color: #1c1c1c;
}
.lightMode .CompoundDtlgrp .MuiFormControlLabel-root.formRadioField {
  color: #1c1c1c;
}

.lightMode .compAnd .compChild,
.lightMode .compOr .compChild,
.lightMode .compThen .compChild {
  display: block;
  margin-bottom: 2px;
  font-size: 14px;
  font-style: italic;
  text-decoration: underline;
  color: #1c1c1c;
  opacity: 0.6;
}
.lightMode .compoundIndv.compAnd {
  margin-left: 10px;
  position: relative;
  padding: 2px 2px 2px 4px;
  border: 1px solid #b2b2b2;
}
.lightMode .stpContainer .stpCheckBoxIndInv {
  background-color: #e1e1e1;
}
.lightMode .compIndCheck {
  background-color: #e1e1e1;
  border: 1px solid #707070;
  left: -30px;
}
.lightMode .compoundName {
  color: #1c1c1c;
}
.lightMode .MuiButtonBase-root.MuiIconButton-root.navIcoBtn {
  padding: 4px;
  color: #333333;
  width: 30px;
  height: 30px;
  border-radius: 4px !important;
  background-color: #cccccc !important;
}
.lightMode .MuiButtonBase-root.MuiIconButton-root.navIcoBtn:hover {
  color: #ffffff;
  background-color: #212121 !important;
}
.lightMode .zoyeControlerbtnGroup {
  background-color: #e0e0e0 !important;
}
.lightMode .ptzContHead {
  color: #1c1c1c;
}

.lightMode .zcbgSection .backmainMenu {
  color: #ff0d0d;
  background-color: #cccccc;
}
.lightMode .tickStatGrp .MuiButtonBase-root.MuiCheckbox-root {
  padding: 4px;
  color: #333333;
}
.lightMode
  .tickStatGrp
  .MuiFormControlLabel-root
  .MuiTypography-root.MuiTypography-body1 {
  color: #333333 !important;
}

.lightMode
  .formControl
  .MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementEnd
  .MuiFormControlLabel-label.Mui-disabled {
  color: rgb(0 0 0 / 50%);
}
